export const currencyOptions = [
  {"name": "Afghan afghani", "symbol": "؋‎", "code": "AFN"},
  {"name": "Albanian lek", "symbol": "Lek", "code": "ALL"},
  {"name": "Algerian dinar", "symbol": "DA", "code": "DZD"},
  {"name": "Angolan kwanza", "symbol": "Kz", "code": "AOA"},
  {"name": "Argentine peso", "symbol": "$", "code": "ARS"},
  {"name": "Armenian dram", "symbol": "֏", "code": "AMD"},
  {"name": "Aruban florin", "symbol": "ƒ", "code": "AWG"},
  {"name": "Australian dollar", "symbol": "$", "code": "AUD"},
  {"name": "Azerbaijani manat", "symbol": "₼", "code": "AZN"},
  {"name": "Bahamian dollar", "symbol": "$", "code": "BSD"},
  {"name": "Bahraini dinar", "symbol": "BD", "code": "BHD"},
  {"name": "Bangladeshi taka", "symbol": "৳", "code": "BDT"},
  {"name": "Barbadian dollar", "symbol": "$", "code": "BBD"},
  {"name": "Belarusian ruble", "symbol": "Br", "code": "BYN"},
  {"name": "Belize dollar", "symbol": "$", "code": "BZD"},
  {"name": "Bermudian dollar", "symbol": "$", "code": "BMD"},
  {"name": "Bhutanese ngultrum", "symbol": "Nu", "code": "BTN"},
  {"name": "Bitcoin (sats)", "symbol": "!", "code": "SAT"},
  {"name": "Bitcoin", "symbol": "₿", "code": "BTC"},
  {"name": "Bolivian boliviano", "symbol": "Bs", "code": "BOB"},
  {"name": "Bosnia and Herzegovina convertible mark", "symbol": "KM", "code": "BAM"},
  {"name": "Botswana pula", "symbol": "P", "code": "BWP"},
  {"name": "Brazilian real", "symbol": "R$", "code": "BRL"},
  {"name": "Brunei dollar", "symbol": "$", "code": "BND"},
  {"name": "Bulgarian lev", "symbol": "Lev", "code": "BGN"},
  {"name": "Burmese kyat", "symbol": "K", "code": "MMK"},
  {"name": "Burundian franc", "symbol": "Fr", "code": "BIF"},
  {"name": "CFP franc", "symbol": "Fr", "code": "XPF"},
  {"name": "Cambodian riel", "symbol": "៛", "code": "KHR"},
  {"name": "Canadian Dollar", "symbol": "$", "code": "CAD"},
  {"name": "Canadian dollar", "symbol": "$", "code": "CAD"},
  {"name": "Cape Verdean escudo", "symbol": "$", "code": "CVE"},
  {"name": "Cayman Islands dollar", "symbol": "$", "code": "KYD"},
  {"name": "Central African CFA franc", "symbol": "Fr", "code": "XAF"},
  {"name": "Chilean peso", "symbol": "$", "code": "CLP"},
  {"name": "Colombian peso", "symbol": "$", "code": "COP"},
  {"name": "Comorian franc", "symbol": "Fr", "code": "KMF"},
  {"name": "Congolese franc", "symbol": "Fr", "code": "CDF"},
  {"name": "Costa Rican colón", "symbol": "₡", "code": "CRC"},
  {"name": "Cuban peso", "symbol": "$", "code": "CUP"},
  {"name": "Czech koruna", "symbol": "Kč", "code": "CZK"},
  {"name": "Danish krone", "symbol": "kr", "code": "DKK"},
  {"name": "Djiboutian franc", "symbol": "Fr", "code": "DJF"},
  {"name": "Dominican peso", "symbol": "$", "code": "DOP"},
  {"name": "Eastern Caribbean dollar", "symbol": "$", "code": "XCD"},
  {"name": "Egyptian pound", "symbol": "LE", "code": "EGP"},
  {"name": "Eritrean nakfa", "symbol": "Nkf", "code": "ERN"},
  {"name": "Ethiopian birr", "symbol": "Br", "code": "ETB"},
  {"name": "Euro", "symbol": "€", "code": "EUR"},
  {"name": "Falkland Islands pound", "symbol": "£", "code": "FKP"},
  {"name": "Fijian dollar", "symbol": "$", "code": "FJD"},
  {"name": "Gambian dalasi", "symbol": "D", "code": "GMD"},
  {"name": "Georgian lari", "symbol": "₾", "code": "GEL"},
  {"name": "Ghanaian cedi", "symbol": "₵", "code": "GHS"},
  {"name": "Gibraltar pound", "symbol": "£", "code": "GIP"},
  {"name": "Guatemalan quetzal", "symbol": "Q", "code": "GTQ"},
  {"name": "Guinean franc", "symbol": "Fr", "code": "GNF"},
  {"name": "Guyanese dollar", "symbol": "$", "code": "GYD"},
  {"name": "Haitian gourde", "symbol": "G", "code": "HTG"},
  {"name": "Honduran lempira", "symbol": "L", "code": "HNL"},
  {"name": "Hong Kong dollar", "symbol": "$", "code": "HKD"},
  {"name": "Hungarian forint", "symbol": "Ft", "code": "HUF"},
  {"name": "Icelandic króna", "symbol": "kr", "code": "ISK"},
  {"name": "Indian rupee", "symbol": "₹", "code": "INR"},
  {"name": "Indonesian rupiah", "symbol": "Rp", "code": "IDR"},
  {"name": "Iranian rial", "symbol": "Rl", "code": "IRR"},
  {"name": "Iraqi dinar", "symbol": "ID", "code": "IQD"},
  {"name": "Israeli new shekel", "symbol": "₪", "code": "ILS"},
  {"name": "Jamaican dollar", "symbol": "$", "code": "JMD"},
  {"name": "Japanese yen", "symbol": "¥", "code": "JPY"},
  {"name": "Jordanian dinar", "symbol": "JD", "code": "JOD"},
  {"name": "Kazakhstani tenge", "symbol": "₸", "code": "KZT"},
  {"name": "Kenyan shilling", "symbol": "Sh", "code": "KES"},
  {"name": "Kuwaiti dinar", "symbol": "KD", "code": "KWD"},
  {"name": "Kyrgyz som", "symbol": "som", "code": "KGS"},
  {"name": "Lao kip", "symbol": "₭", "code": "LAK"},
  {"name": "Lebanese pound", "symbol": "LL", "code": "LBP"},
  {"name": "Lesotho loti", "symbol": "L", "code": "LSL"},
  {"name": "Liberian dollar", "symbol": "$", "code": "LRD"},
  {"name": "Libyan dinar", "symbol": "LD", "code": "LYD"},
  {"name": "Macanese pataca", "symbol": "MOP$", "code": "MOP"},
  {"name": "Macedonian denar", "symbol": "DEN", "code": "MKD"},
  {"name": "Malagasy ariary", "symbol": "Ar", "code": "MGA"},
  {"name": "Malawian kwacha", "symbol": "K", "code": "MWK"},
  {"name": "Malaysian ringgit", "symbol": "RM", "code": "MYR"},
  {"name": "Maldivian rufiyaa", "symbol": "Rf", "code": "MVR"},
  {"name": "Mauritanian ouguiya", "symbol": "UM", "code": "MRU"},
  {"name": "Mauritian rupee", "symbol": "Re", "code": "MUR"},
  {"name": "Mexican peso", "symbol": "$", "code": "MXN"},
  {"name": "Moldovan leu", "symbol": "Leu", "code": "MDL"},
  {"name": "Mongolian tögrög", "symbol": "₮", "code": "MNT"},
  {"name": "Moroccan dirham", "symbol": "DH", "code": "MAD"},
  {"name": "Mozambican metical", "symbol": "Mt", "code": "MZN"},
  {"name": "Namibian dollar", "symbol": "$", "code": "NAD"},
  {"name": "Nepalese rupee", "symbol": "Re", "code": "NPR"},
  {"name": "Netherlands Antillean guilder", "symbol": "ƒ", "code": "ANG"},
  {"name": "New Taiwan dollar", "symbol": "$", "code": "TWD"},
  {"name": "New Zealand dollar", "symbol": "$", "code": "NZD"},
  {"name": "Nicaraguan córdoba", "symbol": "C$", "code": "NIO"},
  {"name": "Nigerian naira", "symbol": "₦", "code": "NGN"},
  {"name": "North Korean won", "symbol": "₩", "code": "KPW"},
  {"name": "Norwegian krone", "symbol": "kr", "code": "NOK"},
  {"name": "Omani rial", "symbol": "RO", "code": "OMR"},
  {"name": "Pakistani rupee", "symbol": "Re", "code": "PKR"},
  {"name": "Panamanian balboa", "symbol": "B/", "code": "PAB"},
  {"name": "Papua New Guinean kina", "symbol": "K", "code": "PGK"},
  {"name": "Paraguayan guaraní", "symbol": "₲", "code": "PYG"},
  {"name": "Peruvian sol", "symbol": "S/", "code": "PEN"},
  {"name": "Philippine peso", "symbol": "₱", "code": "PHP"},
  {"name": "Polish złoty", "symbol": "zł", "code": "PLN"},
  {"name": "Qatari riyal", "symbol": "QR", "code": "QAR"},
  {"name": "Renminbi", "symbol": "¥", "code": "CNY"},
  {"name": "Romanian leu", "symbol": "Leu", "code": "RON"},
  {"name": "Russian ruble", "symbol": "₽", "code": "RUB"},
  {"name": "Rwandan franc", "symbol": "Fr", "code": "RWF"},
  {"name": "Saint Helena pound", "symbol": "£", "code": "SHP"},
  {"name": "Samoan tālā", "symbol": "$", "code": "WST"},
  {"name": "São Tomé and Príncipe dobra", "symbol": "Db", "code": "STN"},
  {"name": "Saudi riyal", "symbol": "Rl", "code": "SAR"},
  {"name": "Serbian dinar", "symbol": "DIN", "code": "RSD"},
  {"name": "Seychellois rupee", "symbol": "Re", "code": "SCR"},
  {"name": "Sierra Leonean leone", "symbol": "Le", "code": "SLE"},
  {"name": "Singapore dollar", "symbol": "$", "code": "SGD"},
  {"name": "Solomon Islands dollar", "symbol": "$", "code": "SBD"},
  {"name": "Somali shilling", "symbol": "Sh", "code": "SOS"},
  {"name": "South African rand", "symbol": "R", "code": "ZAR"},
  {"name": "South Korean won", "symbol": "₩", "code": "KRW"},
  {"name": "South Sudanese pound", "symbol": "(none)", "code": "SSP"},
  {"name": "Sri Lankan rupee", "symbol": "Re", "code": "LKR"},
  {"name": "Sterling", "symbol": "£", "code": "GBP"},
  {"name": "Sudanese pound", "symbol": "LS", "code": "SDG"},
  {"name": "Surinamese dollar", "symbol": "$", "code": "SRD"},
  {"name": "Swazi lilangeni", "symbol": "L", "code": "SZL"},
  {"name": "Swedish krona", "symbol": "kr", "code": "SEK"},
  {"name": "Swiss franc", "symbol": "Fr", "code": "CHF"},
  {"name": "Syrian pound", "symbol": "LS", "code": "SYP"},
  {"name": "Tajikistani somoni", "symbol": "SM", "code": "TJS"},
  {"name": "Tanzanian shilling", "symbol": "Sh", "code": "TZS"},
  {"name": "Thai baht", "symbol": "฿", "code": "THB"},
  {"name": "Tongan paʻanga", "symbol": "T$", "code": "TOP"},
  {"name": "Trinidad and Tobago dollar", "symbol": "$", "code": "TTD"},
  {"name": "Tunisian dinar", "symbol": "DT", "code": "TND"},
  {"name": "Turkish lira", "symbol": "₺", "code": "TRY"},
  {"name": "Turkmenistani manat", "symbol": "m", "code": "TMT"},
  {"name": "Ugandan shilling", "symbol": "Sh", "code": "UGX"},
  {"name": "Ukrainian hryvnia", "symbol": "₴", "code": "UAH"},
  {"name": "United Arab Emirates dirham", "symbol": "Dh", "code": "AED"},
  {"name": "United States dollar", "symbol": "$", "code": "USD"},
  {"name": "Uruguayan peso", "symbol": "$", "code": "UYU"},
  {"name": "Uzbekistani sum", "symbol": "soum", "code": "UZS"},
  {"name": "Vanuatu vatu", "symbol": "VT", "code": "VUV"},
  {"name": "Venezuelan digital bolívar", "symbol": "Bs.D", "code": "VED"},
  {"name": "Venezuelan sovereign bolívar", "symbol": "Bs.S", "code": "VES"},
  {"name": "Vietnamese đồng", "symbol": "₫", "code": "VND"},
  {"name": "West African CFA franc", "symbol": "Fr", "code": "XOF"},
  {"name": "Yemeni rial", "symbol": "Rl", "code": "YER"},
  {"name": "Zambian kwacha", "symbol": "K", "code": "ZMW"},
]
